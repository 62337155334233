import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "../../css/tnp.css";

const Tsinglegame = () => {
  const location = useLocation();
  const { numPlayers } = location.state || { numPlayers: 4 };

  const [players, setPlayers] = useState(
    Array.from({ length: numPlayers }, () => ({
      id: "",
      name: "",
      score4: 0,
      score3: 0,
      wins: 0,
      advance: false,
      finalWinner: false,
      buttonsHidden: false,
      inputCompleted: false,
      selectedBilliardHall: "",
      availablePlayers: [],
      isManualInput: false, // 수기 입력 여부 추가
    }))
  );

  const [memberanking, setMemberanking] = useState([]); // memberanking 데이터 저장
  const [billiardHalls, setBilliardHalls] = useState([]); // 당구장 목록
  const [advancedPlayers, setAdvancedPlayers] = useState([]); // 다음 라운드 진출자 관리
  const [isTournamentStarted, setIsTournamentStarted] = useState(false); // 토너먼트 시작 여부
  const [finalWinner, setFinalWinner] = useState(null); // 최종 우승자 관리

  useEffect(() => {
    // memberanking 데이터 불러오기
    axios
      .get("https://chikka.shop/api/memberanking")
      .then((response) => {
        if (Array.isArray(response.data)) {
          setMemberanking(response.data);

          // 당구장 목록 추출
          const uniqueHalls = [...new Set(response.data.map((player) => player.billiards))];
          setBilliardHalls([...uniqueHalls, "기타"]); // "기타" 옵션 추가
        } else {
          console.error("Unexpected response format:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching memberanking data:", error);
      });
  }, []);

  // 플레이어 선택이 완료된 후 토너먼트를 시작할 수 있도록 설정
  const isReadyToStart = () => {
    return players.every((player) => player.inputCompleted);
  };

  // 토너먼트 시작 함수
  const startTournament = () => {
    if (isReadyToStart()) {
      setIsTournamentStarted(true);
    } else {
      alert("모든 플레이어를 선택해주세요.");
    }
  };

  // 당구장 선택 시 해당 당구장에 속한 플레이어를 필터링
  const handleSelectBilliardHall = (index, billiardHall) => {
    const updatedPlayers = [...players];
    updatedPlayers[index].selectedBilliardHall = billiardHall;

    if (billiardHall === "기타") {
      updatedPlayers[index].isManualInput = true; // 수기 입력 활성화
      updatedPlayers[index].availablePlayers = [];
    } else {
      updatedPlayers[index].isManualInput = false; // 수기 입력 비활성화
      const availablePlayers = memberanking.filter((player) => player.billiards === billiardHall);
      updatedPlayers[index].availablePlayers = availablePlayers;
    }
    setPlayers(updatedPlayers);
  };

  // 플레이어 선택 시 해당 플레이어의 데이터를 가져오는 함수
  const handleSelectPlayer = (index, playerId) => {
    const selectedPlayer = memberanking.find((player) => player.id === playerId);

    if (selectedPlayer) {
      const updatedPlayers = [...players];
      updatedPlayers[index] = {
        ...updatedPlayers[index],
        id: playerId,
        name: selectedPlayer.name,
        score4: selectedPlayer.score4,
        score3: selectedPlayer.score3,
        inputCompleted: true,
      };
      setPlayers(updatedPlayers);
    }
  };

  // 수기 입력 완료 처리
  const handleManualInputComplete = (index) => {
    const updatedPlayers = [...players];
    updatedPlayers[index].inputCompleted = true;
    setPlayers(updatedPlayers);
  };

  // 수기 입력 변경 핸들러
  const handleInputChange = (index, field, value) => {
    const updatedPlayers = [...players];
    updatedPlayers[index][field] = value;
    setPlayers(updatedPlayers);
  };

  // 승리 처리
  const handleWin = (playerIndex) => {
    const updatedPlayers = [...players];
    updatedPlayers[playerIndex].wins += 1;
    setPlayers(updatedPlayers);
  };

  // 다음 라운드 진출 처리
  const handleAdvance = (playerIndex) => {
    const updatedPlayers = [...players];
    updatedPlayers[playerIndex].advance = true;
    updatedPlayers[playerIndex].buttonsHidden = true;

    setPlayers(updatedPlayers);
    setAdvancedPlayers((prev) => [...prev, updatedPlayers[playerIndex]]);

    alert(`${players[playerIndex].id} 플레이어가 다음 라운드로 진출했습니다!`);
  };

  // 최종 우승 처리
  const handleFinalWin = (playerIndex) => {
    const updatedPlayers = [...players];
    updatedPlayers[playerIndex].finalWinner = true;

    setPlayers(updatedPlayers);
    setFinalWinner(updatedPlayers[playerIndex]);

    alert(`${players[playerIndex].id} 플레이어가 최종 우승하였습니다!`);
  };

  // 다음 라운드로 넘어가기
  const handleNextRound = () => {
    setPlayers(
      advancedPlayers.map((player) => ({
        ...player,
        wins: 0,
        advance: false,
        buttonsHidden: false,
      }))
    );
    setAdvancedPlayers([]);
  };

  return (
    <div className="tournament-container">
      <h1>개인전 토너먼트</h1>
      {!isTournamentStarted ? (
        <div className="players-input">
          {players.map((player, index) => (
            <div key={index} className="player-input">
              {/* 당구장 선택 */}
              <select
                onChange={(e) => handleSelectBilliardHall(index, e.target.value)}
                value={player.selectedBilliardHall || ""}
              >
                <option value="">당구장을 선택하세요</option>
                {billiardHalls.map((hall) => (
                  <option key={hall} value={hall}>
                    {hall}
                  </option>
                ))}
              </select>

              {/* 수기 입력 (기타 선택 시) */}
              {!player.inputCompleted && player.selectedBilliardHall === "기타" ? (
                <>
                  <input
                    type="text"
                    placeholder="별칭:"
                    value={player.id}
                    onChange={(e) => handleInputChange(index, "id", e.target.value)}
                  />
                  <input
                    type="number"
                    placeholder="4구 점수:"
                    value={player.score4 === 0 ? "" : player.score4}
                    onChange={(e) => handleInputChange(index, "score4", e.target.value)}
                  />
                  <input
                    type="number"
                    placeholder="3구 점수:"
                    value={player.score3 === 0 ? "" : player.score3}
                    onChange={(e) => handleInputChange(index, "score3", e.target.value)}
                  />
                  <button onClick={() => handleManualInputComplete(index)}>입력 완료</button>
                </>
              ) : player.selectedBilliardHall !== "기타" ? (
                // 플레이어 선택
                <select
                  onChange={(e) => handleSelectPlayer(index, e.target.value)}
                  value={player.id || ""}
                  disabled={!player.selectedBilliardHall}
                >
                  <option value="">플레이어를 선택하세요</option>
                  {player.availablePlayers.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name} (ID: {option.id})
                    </option>
                  ))}
                </select>
              ) : null}

              {player.inputCompleted && (
                <div>
                  <p>별칭: {player.id}</p>
                  <p>4구 점수: {player.score4}</p>
                  <p>3구 점수: {player.score3}</p>
                </div>
              )}
            </div>
          ))}

          {/* 토너먼트 시작 버튼 */}
          <button onClick={startTournament} disabled={!isReadyToStart()}>
            토너먼트 시작
          </button>
        </div>
      ) : (
        <div>
          {/* 플레이어 목록과 승리 버튼 표시 */}
          <div className="round-title">
            <h2>현재 라운드</h2>
          </div>

          {players.map((player, index) => (
            <div key={index} className="player">
              <p>{player.id}</p>
              <p>4구 점수: {player.score4}</p>
              <p>3구 점수: {player.score3}</p>
              <p>승리 횟수: {player.wins}</p>
              {player.advance && <p>다음 라운드 진출</p>}
              {player.finalWinner && <p>최종 우승자</p>}
              {!player.buttonsHidden && (
                <>
                  <button onClick={() => handleWin(index)}>승리</button>
                  {players.length === 2 ? (
                    <button onClick={() => handleFinalWin(index)}>
                      최종 우승
                    </button>
                  ) : (
                    <button onClick={() => handleAdvance(index)}>
                      다음 라운드 진출
                    </button>
                  )}
                </>
              )}
            </div>
          ))}

          {/* 다음 라운드 진출자 표시 */}
          <div className="next-round-players">
            <h3>다음 라운드 진출자:</h3>
            {advancedPlayers.length > 0 ? (
              <ul>
                {advancedPlayers.map((player, index) => (
                  <li key={index}>{player.id}</li>
                ))}
              </ul>
            ) : (
              <p>아직 다음 라운드 진출자가 없습니다.</p>
            )}
          </div>

          {/* 다음 라운드 시작 버튼 */}
          {advancedPlayers.length > 0 && (
            <button onClick={handleNextRound}>다음 라운드 시작</button>
          )}

          {/* 최종 우승자 표시 */}
          {finalWinner && (
            <div className="final-winner">
              <h2>최종 우승자:</h2>
              <p>ID: {finalWinner.id}</p>
              <p>4구 점수: {finalWinner.score4}</p>
              <p>3구 점수: {finalWinner.score3}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Tsinglegame;
