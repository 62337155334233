import React, { useState } from 'react'; 
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios'; 
import '../../css/signup.css'; // CSS 파일 임포트

const Sign = () => {
  const [username, setUsername] = useState(''); // 별칭 필수 입력 -> username으로 변경
  const [phonePart1, setPhonePart1] = useState(''); // 전화번호 필수 입력
  const [phonePart2, setPhonePart2] = useState('');
  const [phonePart3, setPhonePart3] = useState('');
  const [emailPart1, setEmailPart1] = useState(''); // 이메일 필수 입력
  const [emailPart2, setEmailPart2] = useState('');
  const [customDomain, setCustomDomain] = useState(''); // 사용자 정의 도메인
  const [password, setPassword] = useState(''); // 비밀번호 필수 입력
  const [confirmPassword, setConfirmPassword] = useState(''); // 비밀번호 확인 필수 입력
  const [id, setId] = useState(''); // 아이디 필수 입력
  const [score3, setScore3] = useState(''); // 선택 입력
  const [highrun4, setHighrun4] = useState(''); // 선택 입력 (변경됨)
  const [highrun3, setHighrun3] = useState(''); // 선택 입력 (변경됨)
  const [birthYear, setBirthYear] = useState(''); // 선택 입력
  const [score4Options] = useState([30, 50, 80, 100, 120, 150, 200, 250, 300, 400, 500, 700, 1000, 1500, 2000]); // 4구 점수 선택

  const [selectedScore4, setSelectedScore4] = useState(''); // 선택 입력
  const [selectedBilliardHall, setSelectedBilliardHall] = useState(''); // 선택 입력
  const [selectedRegion, setSelectedRegion] = useState(''); // 선택 입력
  const [message, setMessage] = useState('');
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(null);
  const [checkingUsername, setCheckingUsername] = useState(false);
  const navigate = useNavigate();

  const domainOptions = ['naver.com', 'gmail.com', 'hotmail.com', 'hanmail.net'];

  const handleRegionChange = (e) => {
    const selected = e.target.value;
    setSelectedRegion(selected);
  };

  const isValidUsernameOrPassword = (value) => {
    const hasNumber = /\d/.test(value);
    const hasLetter = /[a-zA-Z]/.test(value);
    return value.length >= 4 && hasNumber && hasLetter;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleAlphaNumericKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    const charStr = String.fromCharCode(charCode);
    const isAlphaNumericOrDot = /^[a-zA-Z0-9.]+$/.test(charStr);

    if (!isAlphaNumericOrDot) {
      e.preventDefault();
    }
  };

  const handleDomainSelect = (e) => {
    const selectedDomain = e.target.value;
    if (selectedDomain === 'custom') {
      setEmailPart2('');
    } else {
      setEmailPart2(selectedDomain);
      setCustomDomain('');
    }
  };

  const checkUsernameAvailability = async () => {
    if (!id) {
      setMessage('아이디를 입력하세요.');
      return;
    }

    if (!isValidUsernameOrPassword(id)) {
      setMessage('아이디는 영문과 숫자를 포함한 4자리 이상이어야 합니다.');
      return;
    }

    setCheckingUsername(true);
    try {
      const response = await axios.get(`/api/memberanking/check-username?username=${id}`);
      setMessage(response.data.message);
      setIsUsernameAvailable(true);
    } catch (error) {
      setMessage(error.response?.data?.message || '중복 확인 실패');
      setIsUsernameAvailable(false);
    } finally {
      setCheckingUsername(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidUsernameOrPassword(password)) {
      setMessage('비밀번호는 영문과 숫자를 포함한 4자리 이상이어야 합니다.');
      return;
    }

    if (password !== confirmPassword) {
      setMessage('비밀번호가 일치하지 않습니다.');
      return;
    }

    if (!isUsernameAvailable) {
      setMessage('아이디 중복 확인을 먼저 해주세요.');
      return;
    }

    const phone = `${phonePart1}-${phonePart2}-${phonePart3}`;
    const emailDomain = emailPart2 || customDomain;
    const email = `${emailPart1}@${emailDomain}`;

    if (!isValidEmail(email)) {
      setMessage('올바른 이메일 형식을 입력하세요.');
      return;
    }

    try {
      const response = await axios.post('/api/memberanking/signup', { 
        id, // 아이디 전송 (별칭이 아님)
        phone: phone.replace(/-/g, ''), 
        email, 
        password,
        username, // 별칭(alias)을 username으로 변경하여 전송
        score4: selectedScore4, // 선택 입력
        score3, // 선택 입력
        highrun4, // 선택 입력 (변경됨)
        highrun3, // 선택 입력 (변경됨)
        birthYear, // 선택 입력
        billiardHall: selectedBilliardHall, // 선택 입력
        region: selectedRegion, // 선택 입력
      });
      console.log('Signup response:', response.data);
      setMessage('회원가입 성공');
      setTimeout(() => {
        navigate('/');
      }, 3000);
    } catch (error) {
      console.error('회원가입 오류:', error);
      setMessage('회원가입에 실패했습니다.');
    }
  };
  
  return (
    <form onSubmit={handleSubmit} className="signup-form">
      <h2>회원가입</h2>
      {message && <p className="message">{message}</p>}

      {/* 아이디 입력 및 중복 확인 (필수 입력) */}
      <label>
        아이디:
        <input 
          type="text" 
          value={id} 
          onChange={(e) => setId(e.target.value)} 
          disabled={checkingUsername} 
          required
        />
        <button 
          type="button" 
          onClick={checkUsernameAvailability} 
          disabled={checkingUsername || !id}
        >
          {checkingUsername ? '확인 중...' : '아이디 중복 확인'}
        </button>
      </label>
      {isUsernameAvailable === false && <p className="error">아이디가 이미 사용 중입니다.</p>}
      {isUsernameAvailable === true && <p className="success">아이디를 사용할 수
        있습니다.</p>}

{/* 전화번호 입력 (필수 입력) */}
<label>
  전화번호:
  <div style={{ display: 'flex', gap: '5px' }}>
    <input 
      type="text" 
      value={phonePart1} 
      onChange={(e) => setPhonePart1(e.target.value.slice(0, 4))} 
      maxLength={4}
      style={{ width: '50px' }}
      placeholder="0000"
      onKeyPress={(e) => { if (!/[0-9]/.test(e.key)) e.preventDefault(); }}
      required
    />
    <input 
      type="text" 
      value={phonePart2} 
      onChange={(e) => setPhonePart2(e.target.value.slice(0, 4))} 
      maxLength={4}
      style={{ width: '50px' }}
      placeholder="0000"
      onKeyPress={(e) => { if (!/[0-9]/.test(e.key)) e.preventDefault(); }}
      required
    />
    <input 
      type="text" 
      value={phonePart3} 
      onChange={(e) => setPhonePart3(e.target.value.slice(0, 4))} 
      maxLength={4}
      style={{ width: '50px' }}
      placeholder="0000"
      onKeyPress={(e) => { if (!/[0-9]/.test(e.key)) e.preventDefault(); }}
      required
    />
  </div>
</label>

{/* 이메일 입력 (필수 입력) */}
<label>
  이메일:
  <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
    <input 
      type="text" 
      value={emailPart1} 
      onChange={(e) => setEmailPart1(e.target.value.slice(0, 16))} 
      maxLength={16}
      style={{ width: '100px' }}
      placeholder="example"
      onKeyPress={handleAlphaNumericKeyPress}
      required
    />
    <span>@</span>
    <input 
      type="text" 
      value={emailPart2 || customDomain} 
      onChange={(e) => setCustomDomain(e.target.value)} 
      maxLength={16}
      style={{ width: '100px' }}
      placeholder="domain.com"
      onKeyPress={handleAlphaNumericKeyPress}
      required
    />
    <select onChange={handleDomainSelect} value={emailPart2} style={{ marginLeft: '10px' }}>
      <option value="">도메인 선택</option>
      {domainOptions.map((domain) => (
        <option key={domain} value={domain}>
          {domain}
        </option>
      ))}
      <option value="custom">직접 입력</option>
    </select>
  </div>
</label>

{/* 별칭 입력을 username으로 변경 (필수 입력) */}
<label>
  별칭 (username):
  <input 
    type="text" 
    value={username} 
    onChange={(e) => setUsername(e.target.value)} 
    required
  />
</label>

{/* 4구 점수 선택 드롭다운 (선택 입력) */}
<label>
  4구 점수:
  <select onChange={(e) => setSelectedScore4(e.target.value)}>
    <option value="">점수를 선택하세요</option>
    {score4Options.map((score, index) => (
      <option key={index} value={score}>
        {score}
      </option>
    ))}
  </select>
</label>

{/* 지역 선택 드롭다운 (선택 입력) */}
<label>
  지역:
  <select onChange={handleRegionChange}>
    <option value="">지역을 선택하세요</option>
  </select>
</label>

{/* 당구장 이름 선택 드롭다운 (선택 입력) */}
<label>
  당구장 이름:
  <select onChange={(e) => setSelectedBilliardHall(e.target.value)}>
    <option value="">당구장을 선택하세요</option>
  </select>
</label>

{/* 3구 점수 수기 입력 (선택 입력) */}
<label>
  3구 점수:
  <input 
    type="text" 
    value={score3} 
    onChange={(e) => setScore3(e.target.value)} 
    maxLength={4}
    onKeyPress={(e) => { if (!/[0-9]/.test(e.key)) e.preventDefault(); }}
  />
</label>

{/* 4구 하이런 수기 입력 (선택 입력) */}
<label>
  4구 하이런:
  <input 
    type="text" 
    value={highrun4} 
    onChange={(e) => setHighrun4(e.target.value)} 
    maxLength={4}
    onKeyPress={(e) => { if (!/[0-9]/.test(e.key)) e.preventDefault(); }}
  />
</label>

{/* 3구 하이런 수기 입력 (선택 입력) */}
<label>
  3구 하이런:
  <input 
    type="text" 
    value={highrun3} 
    onChange={(e) => setHighrun3(e.target.value)} 
    maxLength={4}
    onKeyPress={(e) => { if (!/[0-9]/.test(e.key)) e.preventDefault(); }}
  />
</label>

{/* 생년 수기 입력 (선택 입력) */}
<label>
  생년:
  <input 
    type="text" 
    value={birthYear} 
    onChange={(e) => setBirthYear(e.target.value)} 
    maxLength={4}
    onKeyPress={(e) => { if (!/[0-9]/.test(e.key)) e.preventDefault(); }}
  />
</label>

{/* 비밀번호 및 비밀번호 확인 입력 (필수 입력) */}
<label>
  비밀번호:
  <input 
    type="password" 
    value={password} 
    onChange={(e) => setPassword(e.target.value)} 
    required
  />
</label>
<label>
  비밀번호 확인:
  <input 
    type="password" 
    value={confirmPassword} 
    onChange={(e) => setConfirmPassword(e.target.value)} 
    required
  />
</label>

<p className="info-message">
  4구점수, 3구점수, 4구하이런, 3구하이런에 대한 개인정보 등록은 핸디점수등록에서 작성하실 수 있습니다.
  <Link to="/handicap-scores"> 핸디점수등록 바로가기</Link>
</p>

<button type="submit" disabled={!isUsernameAvailable}>
  가입
</button>
</form>
);
};

export default Sign;
