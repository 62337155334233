import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../css/tnp.css'; // CSS 파일 연결

const Tsingleedit = () => {
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [matchDate, setMatchDate] = useState('');
  const [content, setContent] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // 로딩 상태 추가
  const navigate = useNavigate();

  // 매치 정보 불러오기
  useEffect(() => {
    const fetchMatch = async () => {
      try {
        setLoading(true); // 로딩 시작
        const response = await axios.get(`/api/singlematch/${id}`);
        setTitle(response.data.title);
        setMatchDate(response.data.matchDate);
        setContent(response.data.content);
        setLoading(false); // 로딩 완료
      } catch (err) {
        console.error('Error fetching match:', err);
        setError('매치 정보를 불러오지 못했습니다.');
        setLoading(false); // 에러 시 로딩 완료
      }
    };

    fetchMatch();
  }, [id]);

  // 매치 정보 수정 요청
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const token = localStorage.getItem('token');
      await axios.put(`/api/singlematch/${id}`, { title, matchDate, content }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('매치 정보가 수정되었습니다.');
      navigate(`/tournament/single/${id}`);
    } catch (error) {
      console.error('Error editing match:', error);
      alert('매치 수정 중 문제가 발생했습니다.');
    }
  };
  
  if (loading) {
    return <div>로딩 중...</div>; // 로딩 상태 표시
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="promotion-container">
      <h2 className="post-title">개인전 매치 수정</h2>
      <form className="post-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>제목</label>
          <input 
            type="text" 
            value={title} 
            onChange={(e) => setTitle(e.target.value)} 
            required 
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label>매치 날짜</label>
          <input 
            type="text" 
            value={matchDate} 
            onChange={(e) => setMatchDate(e.target.value)} 
            placeholder="예: 2024-09-28"
            required 
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label>내용</label>
          <textarea 
            value={content} 
            onChange={(e) => setContent(e.target.value)} 
            required 
            className="form-textarea"
          />
        </div>
        <button type="submit" className="submit-button">수정 완료</button>
      </form>
    </div>
  );
};

export default Tsingleedit;
