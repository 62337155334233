import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // useParams 제거
import '../../css/upf.css';

const Uprof = () => {
  const [user, setUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedUsername, setUpdatedUsername] = useState('');
  const [updatedPhone, setUpdatedPhone] = useState('');
  const [updatedEmail, setUpdatedEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  // 현재 로그인된 사용자 정보 불러오기
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setErrorMessage('로그인이 필요합니다.');
          return;
        }

        // 서버에서 현재 로그인된 사용자 정보 가져오기
        const response = await axios.get('/api/memberanking/user', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data) {
          setUser(response.data);
          setUpdatedUsername(response.data.username);
          setUpdatedPhone(response.data.phone);
          setUpdatedEmail(response.data.email);
        }
      } catch (error) {
        setErrorMessage('사용자 정보를 가져오는 데 실패했습니다.');
      }
    };

    fetchUser();
  }, []);

  const deleteUser = async () => {
    const confirmed = window.confirm('정말로 회원 탈퇴하시겠습니까?');
    if (!confirmed) return;

    if (!password) {
      alert('비밀번호를 입력해주세요.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setErrorMessage('로그인이 필요합니다.');
        return;
      }

      // 회원 삭제 API 호출
      const response = await axios.delete('/api/memberanking/user', {
        data: { password },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        alert('회원 탈퇴가 완료되었습니다.');
        localStorage.removeItem('token');
        navigate('/');
        window.location.reload();
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || '회원 탈퇴에 실패했습니다. 비밀번호를 확인해주세요.');
    }
  };

  const updateUser = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setErrorMessage('로그인이 필요합니다.');
        return;
      }

      // 사용자 정보 업데이트 API 호출
      const response = await axios.put(
        '/api/memberanking/user',
        {
          username: updatedUsername,
          phone: updatedPhone,
          email: updatedEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setUser(response.data);
      setIsEditing(false);
      alert('회원 정보가 수정되었습니다.');
    } catch (error) {
      setErrorMessage(error.response?.data?.message || '회원 정보 수정에 실패했습니다.');
    }
  };

  return (
    <div className="user-profile-container">
      <h2>회원 정보</h2>
      {errorMessage && <p>{errorMessage}</p>}
      {user ? (
        <div>
          {/* 기본 정보 */}
          <div className="basic-info">
            <h3>기본 정보</h3>
            {isEditing ? (
              <div>
                <label>
                  아이디:
                  <input
                    type="text"
                    value={updatedUsername}
                    onChange={(e) => setUpdatedUsername(e.target.value)}
                  />
                </label>
                <label>
                  전화번호:
                  <input
                    type="text"
                    value={updatedPhone}
                    onChange={(e) => setUpdatedPhone(e.target.value)}
                  />
                </label>
                <label>
                  이메일:
                  <input
                    type="text"
                    value={updatedEmail}
                    onChange={(e) => setUpdatedEmail(e.target.value)}
                  />
                </label>
                <button onClick={updateUser}>저장</button>
                <button className="cancel-btn" onClick={() => setIsEditing(false)}>취소</button>
              </div>
            ) : (
              <div>
                <p>아이디: {user.username}</p>
                <p>전화번호: {user.phone}</p>
                <p>이메일: {user.email}</p>
                <button onClick={() => setIsEditing(true)}>수정</button>
              </div>
            )}
          </div>

          {/* 추가 정보 */}
          <div className="additional-info">
            <h3>추가 정보</h3>
            <p>포인트: {user.points || 0}</p>
            <p>승점: {user.victory || 0}</p> {/* 승점 추가 */}
            <p>별칭: {user.alias || '정보 없음'}</p>
            <p>4구 점수: {user.score4 || '정보 없음'}</p>
            <p>3구 점수: {user.score3 || '정보 없음'}</p>
            <p>4구 하이런: {user.highrun4 || '정보 없음'}</p>
            <p>3구 하이런: {user.highrun3 || '정보 없음'}</p>
            <p>생년: {user.birthYear || '정보 없음'}</p>
            <p>지역: {user.region || '정보 없음'}</p>
            <p>당구장 이름: {user.billiards || '정보 없음'}</p>
            <button onClick={() => navigate('/update-info')}>추가 정보 수정</button> {/* 새로운 페이지로 이동 */}
          </div>

          {/* 회원 탈퇴 */}
          <div className="delete-account">
            <label>
              비밀번호 확인:
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </label>
            <button className="cancel-btn" onClick={deleteUser}>회원 탈퇴</button>
          </div>
        </div>
      ) : (
        <p className="loading-message">회원 정보를 불러오는 중입니다...</p>
      )}
    </div>
  );
};

export default Uprof;
