import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "../../css/tnp.css";

const Tmultigame = () => {
  const location = useLocation();
  const { numPlayers } = location.state || { numPlayers: 4 };

  // 2명씩 팀을 이루도록 초기화
  const [teams, setTeams] = useState(
    Array.from({ length: numPlayers / 2 }, () => ({
      players: [
        { id: "", name: "", score4: 0, score3: 0, inputCompleted: false, selectedBilliardHall: "", availablePlayers: [], isManualInput: false },
        { id: "", name: "", score4: 0, score3: 0, inputCompleted: false, selectedBilliardHall: "", availablePlayers: [], isManualInput: false }
      ],
      wins: 0,
      advance: false,
      finalWinner: false,
      buttonsHidden: false,
    }))
  );

  const [rankings, setRankings] = useState([]);
  const [billiardHalls, setBilliardHalls] = useState([]);
  const [advancedTeams, setAdvancedTeams] = useState([]);
  const [isTournamentStarted, setIsTournamentStarted] = useState(false);
  const [finalWinner, setFinalWinner] = useState(null);

  useEffect(() => {
    axios
      .get("https://chikka.shop/api/rankings")
      .then((response) => {
        if (Array.isArray(response.data)) {
          setRankings(response.data);
          const uniqueHalls = [...new Set(response.data.map((player) => player.billiards))];
          setBilliardHalls([...uniqueHalls, "기타"]);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching rankings data:", error);
      });
  }, []);

  const isReadyToStart = () => {
    return teams.every((team) => team.players.every((player) => player.inputCompleted));
  };

  const startTournament = () => {
    if (isReadyToStart()) {
      setIsTournamentStarted(true);
    } else {
      alert("모든 팀의 플레이어를 선택해주세요.");
    }
  };

  const handleSelectBilliardHall = (teamIndex, playerIndex, billiardHall) => {
    const updatedTeams = [...teams];
    updatedTeams[teamIndex].players[playerIndex].selectedBilliardHall = billiardHall;

    if (billiardHall === "기타") {
      updatedTeams[teamIndex].players[playerIndex].isManualInput = true;
      updatedTeams[teamIndex].players[playerIndex].availablePlayers = [];
    } else {
      updatedTeams[teamIndex].players[playerIndex].isManualInput = false;
      const availablePlayers = rankings.filter((player) => player.billiards === billiardHall);
      updatedTeams[teamIndex].players[playerIndex].availablePlayers = availablePlayers;
    }
    setTeams(updatedTeams);
  };

  const handleSelectPlayer = (teamIndex, playerIndex, playerId) => {
    const selectedPlayer = rankings.find((player) => player.id === playerId);

    if (selectedPlayer) {
      const updatedTeams = [...teams];
      updatedTeams[teamIndex].players[playerIndex] = {
        ...updatedTeams[teamIndex].players[playerIndex],
        id: playerId,
        name: selectedPlayer.name,
        score4: selectedPlayer.score4,
        score3: selectedPlayer.score3,
        inputCompleted: true,
      };
      setTeams(updatedTeams);
    }
  };

  const handleManualInputComplete = (teamIndex, playerIndex) => {
    const updatedTeams = [...teams];
    updatedTeams[teamIndex].players[playerIndex].inputCompleted = true;
    setTeams(updatedTeams);
  };

  const handleInputChange = (teamIndex, playerIndex, field, value) => {
    const updatedTeams = [...teams];
    updatedTeams[teamIndex].players[playerIndex][field] = value;
    setTeams(updatedTeams);
  };

  const handleWin = (teamIndex) => {
    const updatedTeams = [...teams];
    updatedTeams[teamIndex].wins += 1;
    setTeams(updatedTeams);
  };

  const handleAdvance = (teamIndex) => {
    const updatedTeams = [...teams];
    updatedTeams[teamIndex].advance = true;
    updatedTeams[teamIndex].buttonsHidden = true;
    setTeams(updatedTeams);
    setAdvancedTeams((prev) => [...prev, updatedTeams[teamIndex]]);
    alert(`팀 ${teamIndex + 1}이(가) 다음 라운드로 진출했습니다!`);
  };

  const handleFinalWin = (teamIndex) => {
    const updatedTeams = [...teams];
    updatedTeams[teamIndex].finalWinner = true;
    setTeams(updatedTeams);
    setFinalWinner(updatedTeams[teamIndex]);
    alert(`팀 ${teamIndex + 1}이(가) 최종 우승하였습니다!`);
  };

  const handleNextRound = () => {
    setTeams(
      advancedTeams.map((team) => ({
        ...team,
        wins: 0,
        advance: false,
        buttonsHidden: false,
      }))
    );
    setAdvancedTeams([]);
  };

  return (
    <div className="tournament-container">
      <h1>멀티전 토너먼트</h1>
      {!isTournamentStarted ? (
        <div className="teams-input">
          {teams.map((team, teamIndex) => (
            <div key={teamIndex} className="team-input">
              <h3>팀 {teamIndex + 1}</h3>
              {team.players.map((player, playerIndex) => (
                <div key={playerIndex} className="player-input">
                  {/* 당구장 선택 */}
                  <select
                    onChange={(e) => handleSelectBilliardHall(teamIndex, playerIndex, e.target.value)}
                    value={player.selectedBilliardHall || ""}
                  >
                    <option value="">당구장을 선택하세요</option>
                    {billiardHalls.map((hall) => (
                      <option key={hall} value={hall}>
                        {hall}
                      </option>
                    ))}
                  </select>

                  {/* 플레이어 선택 */}
                  {player.selectedBilliardHall && !player.isManualInput && (
                    <select
                      onChange={(e) => handleSelectPlayer(teamIndex, playerIndex, e.target.value)}
                      value={player.id || ""}
                    >
                      <option value="">플레이어를 선택하세요</option>
                      {player.availablePlayers.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name} (ID: {option.id})
                        </option>
                      ))}
                    </select>
                  )}

                  {/* 수기 입력 (기타 선택 시) */}
                  {player.isManualInput && (
                    <>
                      <input
                        type="text"
                        placeholder="별칭:"
                        value={player.id}
                        onChange={(e) => handleInputChange(teamIndex, playerIndex, "id", e.target.value)}
                      />
                      <input
                        type="number"
                        placeholder="4구 점수:"
                        value={player.score4 === 0 ? "" : player.score4}
                        onChange={(e) => handleInputChange(teamIndex, playerIndex, "score4", e.target.value)}
                      />
                      <input
                        type="number"
                        placeholder="3구 점수:"
                        value={player.score3 === 0 ? "" : player.score3}
                        onChange={(e) => handleInputChange(teamIndex, playerIndex, "score3", e.target.value)}
                      />
                      <button onClick={() => handleManualInputComplete(teamIndex, playerIndex)}>
                        입력 완료
                      </button>
                    </>
                  )}

                  {player.inputCompleted && (
                    <div>
                      <p>별칭: {player.id}</p>
                      <p>4구 점수: {player.score4}</p>
                      <p>3구 점수: {player.score3}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}

          {/* 토너먼트 시작 버튼 */}
          <button onClick={startTournament} disabled={!isReadyToStart()}>
            토너먼트 시작
          </button>
        </div>
      ) : (
        <div>
          <div className="round-title">
            <h2>현재 라운드</h2>
          </div>

          {teams.map((team, teamIndex) => (
            <div key={teamIndex} className="team">
              <h3>팀 {teamIndex + 1}</h3>
              {team.players.map((player, playerIndex) => (
                <div key={playerIndex}>
                  <p>플레이어 {playerIndex + 1}: {player.id}</p>
                  <p>4구 점수: {player.score4}</p>
                  <p>3구 점수: {player.score3}</p>
                </div>
              ))}
              <p>팀 승리 횟수: {team.wins}</p>
              {team.advance && <p>다음 라운드 진출</p>}
              {team.finalWinner && <p>최종 우승 팀</p>}
              {!team.buttonsHidden && (
                <>
                  <button onClick={() => handleWin(teamIndex)}>승리</button>
                  {teams.length === 2 ? (
                    <button onClick={() => handleFinalWin(teamIndex)}>최종 우승</button>
                  ) : (
                    <button onClick={() => handleAdvance(teamIndex)}>다음 라운드 진출</button>
                  )}
                </>
              )}
            </div>
          ))}

          {/* 다음 라운드 진출자 표시 */}
          <div className="next-round-teams">
            <h3>다음 라운드 진출 팀:</h3>
            {advancedTeams.length > 0 ? (
              <ul>
                {advancedTeams.map((team, index) => (
                  <li key={index}>팀 {index + 1}</li>
                ))}
              </ul>
            ) : (
              <p>아직 다음 라운드 진출자가 없습니다.</p>
            )}
          </div>

          {advancedTeams.length > 0 && (
            <button onClick={handleNextRound}>다음 라운드 시작</button>
          )}

          {finalWinner && (
            <div className="final-winner">
              <h2>최종 우승 팀:</h2>
              {finalWinner.players.map((player, playerIndex) => (
                <div key={playerIndex}>
                  <p>플레이어 {playerIndex + 1}: {player.id}</p>
                  <p>4구 점수: {player.score4}</p>
                  <p>3구 점수: {player.score3}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Tmultigame;

