import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../css/tnp.css'; // CSS 파일 연결

const Tsingledetail = () => {
  const { id } = useParams(); // URL에서 id를 가져옵니다.
  const [post, setPost] = useState(null);
  const [applicants, setApplicants] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAuthor, setIsAuthor] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentUser, setCurrentUser] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  // 신청자 목록 불러오기
  const fetchApplicants = useCallback(async (postId) => {
    try {
      const response = await axios.get(`/api/singlematch/${postId}/applicants`);
      setApplicants(response.data);
    } catch (error) {
      console.error('신청자 목록을 불러오는 중 오류가 발생했습니다.', error);
      setError('신청자 목록을 불러오는 중 문제가 발생했습니다.');
    }
  }, []);

  // 사용자 권한 확인
  const checkUserPermissions = useCallback((author) => {
    const token = localStorage.getItem("token");
    if (token) {
      axios
        .get("/api/members/user", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const user = response.data;
          setCurrentUser(user.username);
          setIsAuthor(user.username === author);
          setIsAdmin(user.role === 'admin');
        })
        .catch((error) => {
          console.error("사용자 확인 중 오류가 발생했습니다.", error);
        });
    }
  }, []);

  // 매치 정보 불러오기
  const fetchPost = useCallback(async (postId) => {
    try {
      setLoading(true);
      const response = await axios.get(`/api/singlematch/${postId}`);
      setPost(response.data);
      checkUserPermissions(response.data.author);
    } catch (error) {
      console.error('매치 정보를 불러오는 중 오류가 발생했습니다.', error);
      setError('매치 정보를 불러오는 중 문제가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  }, [checkUserPermissions]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    if (id) {
      fetchPost(id);
      fetchApplicants(id);
    }
  }, [id, fetchPost, fetchApplicants]);

  const isAlreadyApplied = applicants.some(applicant => applicant.username === currentUser || applicant === currentUser);

  const handleDeletePost = async () => {
    const confirmed = window.confirm('정말로 이 게시글을 삭제하시겠습니까?');
    if (!confirmed) return;

    if (!isAuthor && !isAdmin) {
      alert('게시글을 삭제할 권한이 없습니다.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/api/singlematch/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('게시글이 삭제되었습니다.');
      navigate('/tournament-type');
    } catch (error) {
      console.error('게시글 삭제 중 오류가 발생했습니다.', error);
      alert('게시글 삭제 중 문제가 발생했습니다.');
    }
  };

  const handleEditPost = () => {
    if (!isAuthor && !isAdmin) {
      alert('게시글을 수정할 권한이 없습니다.');
      return;
    }
    navigate(`/singlematch/edit/${id}`);
  };

  const handleApply = async () => {
    if (!isLoggedIn) {
      alert('로그인 후 신청할 수 있습니다.');
      return;
    }
  
    const token = localStorage.getItem('token');
    try {
      await axios.post(
        `/api/singlematch/${id}/apply`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert('신청이 완료되었습니다.');
      fetchApplicants(id);
    } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data.message === "이미 신청하셨습니다.") {
        alert("이미 신청하셨습니다.");
      } else {
        console.error('신청 중 오류가 발생했습니다.', error);
        setError('신청 중 문제가 발생했습니다.');
      }
    }
  };

  if (loading) {
    return <div>로딩 중...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!post) {
    return <div>게시글을 불러오는 중...</div>;
  }

  return (
    <div className="promotion-container">
      <h2>{post.title}</h2>
      <p>{post.content}</p>
      <p>작성자: {post.author || '작성자 없음'}</p>
      <p>작성일: {new Date(post.date).toLocaleString()}</p>
      <p>조회수: {post.viewCount || 0}</p>

      <div className="promotion-actions">
        {(isAuthor || isAdmin) ? (
          <>
            <button onClick={handleEditPost}>수정</button>
            <button className="delete" onClick={handleDeletePost}>삭제</button>
          </>
        ) : (
          <p>수정 및 삭제 권한이 없습니다.</p>
        )}
      </div>

      <div>
        <button onClick={handleApply} disabled={isAlreadyApplied}>
          {isAlreadyApplied ? "이미 신청하셨습니다." : "신청하기"}
        </button>
      </div>

      <div className="applicant-list">
        <h3>신청자 목록</h3>
        {applicants.length > 0 ? (
          <ul>
            {applicants.map((applicant, index) => (
              <li key={index}>
                <p>아이디: {applicant.username || '아이디 없음'}</p>
                <p>별칭: {applicant.alias || '별칭 없음'}</p>
                <p>당구장 이름: {applicant.billiards || '당구장 이름 없음'}</p>
              </li>  
            ))}
          </ul>
        ) : (
          <p>신청자가 없습니다.</p>
        )}
      </div>
    </div>
  );
};

export default Tsingledetail;
