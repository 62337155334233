import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Freebd = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem('token');
    if (!token) {
      alert('로그인이 필요합니다.');
      navigate('/login');
      return;
    }

    try {
      const response = await axios.post('/api/freeboard', { title, content }, {
        headers: {
          Authorization: `Bearer ${token}`, // 인증 토큰을 헤더에 포함
        },
      });

      console.log('작성 성공:', response.data);
      navigate('/free-board'); // 글 작성 후 자유게시판 목록으로 이동
    } catch (error) {
      console.error('글 작성 중 오류:', error);
      alert('글 작성 중 문제가 발생했습니다.');
    }
  };

  return (
    <div>
      <h2>자유 게시판 글쓰기</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>제목</label>
          <input 
            type="text" 
            value={title} 
            onChange={(e) => setTitle(e.target.value)} 
            required 
          />
        </div>
        <div>
          <label>내용</label>
          <textarea 
            value={content} 
            onChange={(e) => setContent(e.target.value)} 
            required 
          />
        </div>
        <button type="submit">작성 완료</button>
      </form>
    </div>
  );
};

export default Freebd;
