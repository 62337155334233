import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';  // 만약 서버로 데이터 전송이 필요하다면 axios 추가
import "../../css/tnp.css";

const Tournamentsetup = () => {
  const [numPlayers, setNumPlayers] = useState(4); // 기본 참가 인원 설정
  const navigate = useNavigate();

  // 토너먼트 설정 API 호출 예시
  const handleStartTournament = async () => {
    try {
      // 만약 서버로 참가자 수를 전송해야 한다면
      await axios.post('/api/tournament/setup', { numPlayers });

      // 설정 완료 후 토너먼트 페이지로 이동
      navigate("/tournament", { state: { numPlayers } });
    } catch (error) {
      console.error("토너먼트 설정 중 오류 발생:", error);
      alert("토너먼트 설정에 실패했습니다.");
    }
  };

  return (
    <div className="tournament-setup-container">
      <h1>당구장 토너먼트 설정</h1>
      <div className="setup-content">
        <label>참가 인원을 선택하세요:</label>
        <select
          value={numPlayers}
          onChange={(e) => setNumPlayers(parseInt(e.target.value))}
        >
          {[4, 8, 16, 32, 64].map((n) => (
            <option key={n} value={n}>
              {n}명
            </option>
          ))}
        </select>
        <button onClick={handleStartTournament}>토너먼트 인원설정완료</button>
      </div>
    </div>
  );
};

export default Tournamentsetup;
