import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "../../css/tnp.css";

const Tournamentgame = () => {
  const location = useLocation();
  const { numPlayers } = location.state || { numPlayers: 4 };

  const [players, setPlayers] = useState(
    Array.from({ length: numPlayers }, () => ({
      id: "",
      name: "",
      score4: 0,
      score3: 0,
      wins: 0,
      advance: false,
      eliminated: false,
      finalWinner: false,
      winner: false,
      loser: false,
      buttonsHidden: false,
      isManualInput: false, // 수기 입력 여부
      inputCompleted: false, // 입력 완료 상태
      selectedBilliardHall: "", // 각 플레이어마다 당구장을 선택할 수 있게 추가
      availablePlayers: [], // 선택한 당구장에 속한 플레이어 목록
    }))
  );

  const [rankings, setRankings] = useState([]); // rankings 데이터 저장
  const [billiardHalls, setBilliardHalls] = useState([]); // 당구장 목록
  const [isTournamentStarted, setIsTournamentStarted] = useState(false); // 토너먼트 시작 여부
  const [roundTitle, setRoundTitle] = useState(""); // 라운드 제목
  const [advancedPlayers, setAdvancedPlayers] = useState([]); // 다음 라운드로 진출한 플레이어들 관리
  const [finalWinner, setFinalWinner] = useState(null); // 최종 우승자 관리

  useEffect(() => {
    // rankings 데이터 불러오기
    axios
      .get("https://chikka.shop/api/rankings")
      .then((response) => {
        if (Array.isArray(response.data)) {
          setRankings(response.data);

          // 당구장 목록 추출
          const uniqueHalls = [...new Set(response.data.map((player) => player.billiards))];
          setBilliardHalls([...uniqueHalls, "기타"]); // "기타" 옵션 추가
        } else {
          console.error("Unexpected response format:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching rankings data:", error);
      });

    setRoundTitle(getRoundTitle(numPlayers));
  }, [numPlayers]);

  // 라운드 제목 설정 함수
  const getRoundTitle = (numPlayersLeft) => {
    if (numPlayersLeft === 2) return "결승전";
    if (numPlayersLeft === 4) return "준결승전";
    if (numPlayersLeft === 8) return "8강전";
    if (numPlayersLeft === 16) return "16강전";
    return `${numPlayersLeft}강전`;
  };

  // 플레이어 선택이 완료된 후 토너먼트를 시작할 수 있도록 설정
  const isReadyToStart = () => {
    return players.every(player => player.inputCompleted);
  };

  // 토너먼트 시작 함수 (플레이어가 모두 선택된 경우에만 시작)
  const startTournament = () => {
    if (isReadyToStart()) {
      setIsTournamentStarted(true);
    } else {
      alert("모든 플레이어를 선택해주세요.");
    }
  };

  // 플레이어 승리 처리
  const handleWin = (playerIndex) => {
    const updatedPlayers = [...players];
    updatedPlayers[playerIndex].wins += 1;
    setPlayers(updatedPlayers);
  };

  // 다음 라운드 진출 처리
  const handleAdvance = (playerIndex, opponentIndex) => {
    const updatedPlayers = [...players];
    updatedPlayers[playerIndex].advance = true;
    updatedPlayers[playerIndex].winner = true;
    updatedPlayers[opponentIndex].eliminated = true;
    updatedPlayers[opponentIndex].loser = true;

    updatedPlayers[playerIndex].buttonsHidden = true;
    updatedPlayers[opponentIndex].buttonsHidden = true;

    setPlayers(updatedPlayers);
    setAdvancedPlayers((prev) => [...prev, updatedPlayers[playerIndex]]);

    alert(`${players[playerIndex].id} 플레이어가 다음 라운드로 진출했습니다!`);
  };

  // 최종 우승 처리
  const handleFinalWin = (playerIndex, opponentIndex) => {
    const updatedPlayers = [...players];
    updatedPlayers[playerIndex].finalWinner = true;
    updatedPlayers[playerIndex].winner = true;
    updatedPlayers[opponentIndex].loser = true;

    updatedPlayers[playerIndex].buttonsHidden = true;
    updatedPlayers[opponentIndex].buttonsHidden = true;

    setPlayers(updatedPlayers);
    setFinalWinner(updatedPlayers[playerIndex]);

    alert(`${players[playerIndex].id} 플레이어가 최종 우승하였습니다!`);
  };

  // 다음 라운드로 넘어가기
  const handleNextRound = () => {
    setPlayers(
      advancedPlayers.map((player) => ({
        ...player,
        wins: 0,
        advance: false,
        buttonsHidden: false,
        winner: false,
        loser: false,
      }))
    );

    setRoundTitle(getRoundTitle(advancedPlayers.length));
    setAdvancedPlayers([]); // 다음 라운드 시작 시 진출한 플레이어 초기화
  };

  // 당구장 선택 시 해당 당구장에 속한 플레이어를 필터링 또는 수기 입력 활성화
  const handleSelectBilliardHall = (index, billiardHall) => {
    const updatedPlayers = [...players];
    updatedPlayers[index].selectedBilliardHall = billiardHall;

    if (billiardHall === "기타") {
      // 수기 입력 활성화
      updatedPlayers[index].isManualInput = true;
      updatedPlayers[index].availablePlayers = []; // 수기 입력이므로 선택할 플레이어 없음
    } else {
      updatedPlayers[index].isManualInput = false; // 수기 입력 비활성화
      // 선택한 당구장에 속한 플레이어 필터링
      const availablePlayers = rankings.filter((player) => player.billiards === billiardHall);
      updatedPlayers[index].availablePlayers = availablePlayers; // 해당 당구장에 속한 플레이어 목록 저장
    }

    setPlayers(updatedPlayers);
  };

  // 플레이어 선택 시 해당 플레이어의 데이터를 가져오는 함수
  const handleSelectPlayer = (index, playerId) => {
    const selectedPlayer = rankings.find((player) => player.id === playerId);

    if (selectedPlayer) {
      const updatedPlayers = [...players];
      updatedPlayers[index] = {
        ...updatedPlayers[index],
        id: playerId,
        name: selectedPlayer.name,
        score4: selectedPlayer.score4,
        score3: selectedPlayer.score3,
        inputCompleted: true,
      };
      setPlayers(updatedPlayers);
    }
  };

  // 수기 입력 시 값 업데이트
  const handleInputChange = (index, field, value) => {
    const updatedPlayers = [...players];
    updatedPlayers[index] = {
      ...updatedPlayers[index],
      [field]: value,
    };
    setPlayers(updatedPlayers);
  };

  // 수기 입력 완료 시 호출
  const handleManualInputComplete = (index) => {
    const updatedPlayers = [...players];
    updatedPlayers[index].inputCompleted = true; // 입력 완료 상태로 변경
    setPlayers(updatedPlayers);
  };

  // 매치 정보를 화면에 표시하는 함수
  const renderPlayers = () => {
    return players.reduce((acc, player, index) => {
      if (index % 2 === 0 && players[index + 1]) {
        const opponent = players[index + 1];
        const isFinalRound = players.length === 2;
        acc.push(
          <div key={index / 2} className="match">
            <div className="player">
              <p>{player.id}</p>
              <p>4구 점수: {player.score4}</p>
              <p>3구 점수: {player.score3}</p>
              <p>승리 횟수: {player.wins}</p>
              {player.winner && <p>승리</p>}
              {player.loser && <p>패배</p>}
              {!player.eliminated && !player.buttonsHidden && (
                <>
                  <button onClick={() => handleWin(index)}>승리</button>
                  {player.advance ? (
                    <button disabled>다음 라운드 진출</button>
                  ) : (
                    <button
                      onClick={() =>
                        isFinalRound
                          ? handleFinalWin(index, index + 1)
                          : handleAdvance(index, index + 1)
                      }
                    >
                      {isFinalRound ? "최종 우승" : "다음 라운드 진출"}
                    </button>
                  )}
                </>
              )}
            </div>
            <div className="vs">VS</div>
            <div className="player">
              <p>{opponent.id}</p>
              <p>4구 점수: {opponent.score4}</p>
              <p>3구 점수: {opponent.score3}</p>
              <p>승리 횟수: {opponent.wins}</p>
              {opponent.winner && <p>승리</p>}
              {opponent.loser && <p>패배</p>}
              {!opponent.eliminated && !opponent.buttonsHidden && (
                <>
                  <button onClick={() => handleWin(index + 1)}>승리</button>
                  {opponent.advance ? (
                    <button disabled>다음 라운드 진출</button>
                  ) : (
                    <button
                      onClick={() =>
                        isFinalRound
                          ? handleFinalWin(index + 1, index)
                          : handleAdvance(index + 1, index)
                      }
                    >
                      {isFinalRound ? "최종 우승" : "다음 라운드 진출"}
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        );
      }
      return acc;
    }, []);
  };

  return (
    <div className="tournament-container">
      <h1>당구장 토너먼트</h1>
      {!isTournamentStarted ? (
        <div className="players-input">
          {players.map((player, index) => (
            <div key={index} className="player-input">
              <select
                onChange={(e) => handleSelectBilliardHall(index, e.target.value)}
                value={player.selectedBilliardHall || ""}
              >
                <option value="">당구장을 선택하세요</option>
                {billiardHalls.map((hall) => (
                  <option key={hall} value={hall}>
                    {hall}
                  </option>
                ))}
              </select>

              {!player.inputCompleted && player.selectedBilliardHall === "기타" ? (
                <>
                  {/* 수기 입력 */}
                  <input
                    type="text"
                    placeholder="별칭:"
                    value={player.id} // 수기 입력 시 별칭은 id 필드에 저장
                    onChange={(e) => handleInputChange(index, "id", e.target.value)}
                  />
                  <input
                    type="number"
                    placeholder="4구 점수:"
                    value={player.score4 === 0 ? "" : player.score4}
                    onChange={(e) => handleInputChange(index, "score4", e.target.value)}
                  />
                  <input
                    type="number"
                    placeholder="3구 점수:"
                    value={player.score3 === 0 ? "" : player.score3}
                    onChange={(e) => handleInputChange(index, "score3", e.target.value)}
                  />
                  <button onClick={() => handleManualInputComplete(index)}>입력 완료</button>
                </>
              ) : player.selectedBilliardHall !== "기타" ? (
                // 플레이어 선택
                <select
                  onChange={(e) => handleSelectPlayer(index, e.target.value)}
                  value={player.id || ""}
                  disabled={!player.selectedBilliardHall}
                >
                  <option value="">플레이어를 선택하세요</option>
                  {player.availablePlayers.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name} (ID: {option.id})
                    </option>
                  ))}
                </select>
              ) : null}

              {player.inputCompleted && (
                <div>
                  <p>별칭: {player.id}</p>
                  <p>4구 점수: {player.score4}</p>
                  <p>3구 점수: {player.score3}</p>
                </div>
              )}
            </div>
          ))}

          {/* 토너먼트 시작 버튼 */}
          <button onClick={startTournament} disabled={!isReadyToStart()}>
            토너먼트 시작
          </button>
        </div>
      ) : (
        <div>
          <div className="round-title">
            <h2>{roundTitle}</h2>
          </div>

          {/* 매치 표시 및 라운드 진행 */}
          {renderPlayers()}

          {/* 다음 라운드 시작 버튼 */}
          {advancedPlayers.length > 0 && (
            <button onClick={handleNextRound}>다음 라운드 시작</button>
          )}

          {/* 최종 우승자 표시 */}
          {finalWinner && (
            <div className="final-winner">
              <h2>최종 우승자:</h2>
              <p>ID: {finalWinner.id}</p>
              <p>4구 점수: {finalWinner.score4}</p>
              <p>3구 점수: {finalWinner.score3}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Tournamentgame;
