import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "../../css/tnp.css";

const Tsinglesetup = () => {
  const [numPlayers, setNumPlayers] = useState(2); // 기본 참가 인원 설정
  const navigate = useNavigate();

  const [posts, setPosts] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);

    const checkAdminStatus = async () => {
      if (token) {
        try {
          const response = await axios.get('/api/check-admin', {
            headers: { Authorization: `Bearer ${token}` }
          });
          setIsAdmin(response.data.isAdmin);
        } catch (error) {
          console.error('Error checking admin status:', error);
        }
      }
    };
    checkAdminStatus();
  }, []);

  const fetchPosts = async () => {
    try {
      const response = await axios.get(`/api/singlematch`);
      const postsWithNumbers = response.data.map((post, index) => ({
        ...post,
        number: index + 1
      }));
      setPosts(postsWithNumbers);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const handleStartTournament = () => {
    navigate("/tournament/single/start", { state: { numPlayers } });
  };

  const handlePromotionRequest = () => {
    if (!isLoggedIn) {
      alert('로그인 후 홍보가 가능합니다.');
      navigate('/login');
      return;
    }
    navigate('/tournament/single/new');
  };

  const sortData = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sorted = [...posts].sort((a, b) => {
      const aValue = a[key] ? a[key].toString() : '';
      const bValue = b[key] ? b[key].toString() : '';

      if (key === 'number') {
        return direction === 'asc' ? a.number - b.number : b.number - a.number;
      } else if (key === 'title' || key === 'date' || key === 'createdAt') {
        return direction === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
      return 0;
    });

    setPosts(sorted);
  };

  const renderArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? '▲' : '▼';
    }
    return '▲';
  };

  return (
    <div className="tournament-setup-container">
      <h1>개인전 토너먼트 설정</h1>
      <div className="setup-content">
        <label>참가 인원을 선택하세요:</label>
        <select
          value={numPlayers}
          onChange={(e) => setNumPlayers(parseInt(e.target.value))}
        >
          {[...Array(15).keys()].map((n) => (
            <option key={n + 2} value={n + 2}>
              {n + 2}명
            </option>
          ))}
        </select>
        <button onClick={handleStartTournament}>개인전 토너먼트 시작</button>
      </div>

      {/* 개인전 매치 게시판 추가 */}
      <div className="promotion-board">
        <h3>개인전 매치 게시판</h3>

        {isAdmin && (
          <div style={{ textAlign: 'right', marginBottom: '10px' }}>
            <button className="promotion-button" onClick={handlePromotionRequest}>
              매치 만들기
            </button>
          </div>
        )}

        <table>
          <thead>
            <tr>
              <th onClick={() => sortData('number')}>번호 {renderArrow('number')}</th>
              <th onClick={() => sortData('title')}>제목 {renderArrow('title')}</th>
              <th onClick={() => sortData('date')}>대회 날짜 {renderArrow('date')}</th>
              <th onClick={() => sortData('createdAt')}>작성 날짜 {renderArrow('createdAt')}</th>
            </tr>
          </thead>
          <tbody>
            {posts.length > 0 ? (
              posts.map((post) => (
                <tr key={post.id}>
                  <td>{post.number}</td>
                  <td>
                    <Link to={`/tournament/single/${post.id}`}>{post.title}</Link>
                  </td>
                  <td>{post.date || '대회 날짜 없음'}</td>
                  <td>{post.createdAt ? new Date(post.createdAt).toLocaleDateString() : '작성 날짜 없음'}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">게시글이 없습니다.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Tsinglesetup;
