import React from "react";
import "../css/table.css";

const sujiToImageMap = {
  30: "30.png",
  50: "50.png",
  80: "80.png",
  100: "100.png",
  120: "120.png",
  150: "150.png",
  200: "200.png",
  250: "250.png",
  300: "300.png",
  400: "400.png",
  500: "500.png",
  700: "700.png",
  1000: "1000.png",
  1500: "1500.png",
  2000: "2000.png",
};

const Rkt = ({
  currentItems,
  sortConfig = {},
  requestSort,
  currentPage,
  itemsPerPage,
  totalItems, // 전체 항목 수 전달받기
  selectedTab, // 선택된 탭에 따른 칼럼 변경을 위한 상태값
}) => {
  // 선택된 탭에 따라 칼럼을 다르게 설정
  const detailedHeaders = [
    { key: "id", label: "별칭" },
    { key: "score4", label: "4구 점수" },
    { key: "score3", label: "3구 점수" },
    { key: "highrun4", label: "4구 하이런" },
    { key: "highrun3", label: "3구 하이런" },
    { key: "birthYear", label: "생년" },
    { key: "region", label: "지역" },
    { key: "billiards", label: "당구장이름" },
  ];

  const defaultHeaders = [
    { key: "id", label: "별칭" },
    { key: "score4", label: "4구 점수" },
    { key: "score3", label: "3구 점수" },
    { key: "points", label: "포인트" },
    { key: "victory", label: "승점" },
    { key: "region", label: "지역" },
    { key: "billiards", label: "당구장이름" },
  ];

  // 'detailed' 탭 선택 시 상세 컬럼 사용
  const columnHeaders = selectedTab === "detailed" ? detailedHeaders : defaultHeaders;

  const getScoreClass = (score) => {
    return `score-${score}`;
  };

  const renderArrow = (key, sortConfig) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? "▲" : "▼";
    }
    return "▲";
  };

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th
              className={`sortable ${sortConfig.key === "number" ? `sort-${sortConfig.direction}` : ""}`}
              onClick={() => requestSort("number")}
            >
              번호 {renderArrow("number", sortConfig)}
            </th>
            <th>등급</th>
            {columnHeaders.map(({ key, label }) => (
              <th
                key={key}
                className={`sortable ${sortConfig.key === key ? `sort-${sortConfig.direction}` : ""}`}
                onClick={() => requestSort(key)}
              >
                {label} {renderArrow(key, sortConfig)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentItems
            .filter((rank) => rank.role !== "admin") // 관리자 계정을 필터링
            .map((rank, index) => {
              const globalIndex =
                sortConfig.direction === "ascending"
                  ? (currentPage - 1) * itemsPerPage + (index + 1)
                  : totalItems - ((currentPage - 1) * itemsPerPage + index);

              return (
                <tr key={index}>
                  <td>{isNaN(globalIndex) ? "-" : globalIndex}</td>
                  {/* 이미지 코드 추가 */}
                  <td>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/ball/${sujiToImageMap[rank.score4] || "defaultImage.png"}`}
                      alt={`Rank for score4 ${rank.score4}`}
                      className="rank-image"
                    />
                  </td>
                  {columnHeaders.map(({ key }) => (
                    <td key={key} className={key === "score4" ? getScoreClass(rank[key]) : ""}>
                      {rank[key]}
                    </td>
                  ))}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default Rkt;
