import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../css/free.css'; // free.css 파일 연결

const Freetail = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAuthor, setIsAuthor] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  const fetchUserInfo = useCallback(async (author) => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const response = await axios.get("/api/members/user", {
          headers: { Authorization: `Bearer ${token}` },
        });
        const user = response.data;
        setIsAuthor(user.username === author);
        setIsAdmin(user.role === "admin");
      } catch (error) {
        console.error("사용자 확인 중 오류가 발생했습니다.", error);
      }
    }
  }, []);

  const fetchPost = useCallback(async (postId) => {
    try {
      setLoading(true);
      const response = await axios.get(`/api/freeboard/${postId}`);
      setPost(response.data);
      fetchUserInfo(response.data.author);
    } catch (error) {
      console.error('게시글을 불러오는 중 오류가 발생했습니다.', error);
      setError('게시글을 불러오는 중 문제가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  }, [fetchUserInfo]);

  const fetchComments = useCallback(async (postId) => {
    try {
      const response = await axios.get(`/api/freeboard/${postId}/comments`);
      setComments(response.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setComments([]);
        setError(null);
      } else {
        console.error('댓글을 불러오는 중 오류가 발생했습니다.', error);
        setError('댓글을 불러오는 중 문제가 발생했습니다.');
      }
    }
  }, []);

  useEffect(() => {
    fetchPost(id);
    fetchComments(id);
  }, [id, fetchPost, fetchComments]);

  const handleDeletePost = async () => {
    const confirmed = window.confirm('정말로 이 게시글을 삭제하시겠습니까?');
    if (!confirmed) return;

    if (!isAuthor && !isAdmin) {
      alert('게시글을 삭제할 권한이 없습니다.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/api/freeboard/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('게시글이 삭제되었습니다.');
      navigate('/free-board');
    } catch (error) {
      console.error('게시글 삭제 중 오류가 발생했습니다.', error);
      alert('게시글 삭제 중 문제가 발생했습니다.');
    }
  };

  const handleEditPost = () => {
    if (!isAuthor && !isAdmin) {
      alert('게시글을 수정할 권한이 없습니다.');
      return;
    }
    navigate(`/free-board/edit/${id}`);
  };

  const handleAddComment = async () => {
    if (!newComment.trim()) {
      alert('댓글 내용을 입력해주세요.');
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      alert('로그인 후 댓글을 작성할 수 있습니다.');
      return;
    }

    try {
      await axios.post(
        `/api/freeboard/${id}/comments`,
        { content: newComment },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchComments(id);
      setNewComment('');
    } catch (error) {
      console.error('댓글 추가 중 오류가 발생했습니다.', error);
      setError('댓글 추가 중 문제가 발생했습니다.');
    }
  };

  const handleDeleteComment = async (commentId) => {
    const confirmed = window.confirm('정말로 이 댓글을 삭제하시겠습니까?');
    if (!confirmed) return;

    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/api/freeboard/${id}/comments/${commentId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setComments(comments.filter((comment) => comment.id !== commentId));
    } catch (error) {
      console.error('댓글 삭제 중 오류가 발생했습니다.', error);
      alert('댓글 삭제 중 문제가 발생했습니다.');
    }
  };

  if (loading) {
    return <div>로딩 중...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!post) {
    return <div>게시글을 불러오는 중...</div>;
  }

  return (
    <div className="freeboard-container">
      <div className="post-detail">
        <h2 className="post-title">{post.title}</h2>
        <p className="post-content">{post.content}</p>
        <p>작성자: {post.author || '작성자 없음'}</p>
        <p>작성일: {new Date(post.date).toLocaleString()}</p>
        <p>조회수: {post.viewCount || 0}</p>

        <div className="post-actions">
          {(isAuthor || isAdmin) ? (
            <>
              <button onClick={handleEditPost}>수정</button>
              <button onClick={handleDeletePost}>삭제</button>
            </>
          ) : (
            <p>수정 및 삭제 권한이 없습니다.</p>
          )}
        </div>
      </div>

      <div className="comments-section">
        <h3>댓글</h3>
        {comments.length > 0 ? (
          comments.map((comment) => (
            <div key={comment.id} className="comment">
              <p>{comment.content}</p>
              <p className="comment-author">작성자: {comment.author}</p>
              <p className="comment-date">작성일: {new Date(comment.date).toLocaleString()}</p>
              <div className="comment-actions">
                <button onClick={() => handleDeleteComment(comment.id)}>댓글 삭제</button>
              </div>
            </div>
          ))
        ) : (
          <p>댓글이 없습니다.</p>
        )}
      </div>

      <div>
        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="댓글을 입력하세요"
        />
        <button className="comment-submit-button" onClick={handleAddComment}>
          댓글 추가
        </button>
      </div>
    </div>
  );
};

export default Freetail;
