import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Link 사용
import '../../css/aml.css'; // CSS 파일을 임포트

const Amemberl = () => {
  const [members, setMembers] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [editingMemberId, setEditingMemberId] = useState(null); // 수정 중인 회원 ID 상태
  const [editPoints, setEditPoints] = useState(''); // 수정 중인 포인트 값
  const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'asc' }); // 정렬 상태 추가

  useEffect(() => {
    fetchMembers(); // 컴포넌트 마운트 시 회원 목록을 가져옴
  }, []);

  const fetchMembers = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setErrorMessage('로그인이 필요합니다.');
        return;
      }
  
      const response = await axios.get('/api/memberanking', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
  
      if (response && Array.isArray(response.data)) {
        setMembers(response.data);
      } else {
        setErrorMessage('회원 정보를 불러오는 데 실패했습니다.');
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      setErrorMessage('회원 정보를 불러오는 데 실패했습니다.');
      console.error('Error fetching members:', error); // 여기에 세미콜론 추가
    }
  };
  
  const deleteMember = async (id) => {
    console.log("Attempting to delete member with ID:", id); // 로그 추가
    const confirmDelete = window.confirm('정말로 삭제하시겠습니까?');
    if (!confirmDelete) return;
  
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setErrorMessage('로그인이 필요합니다.');
        return;
      }
  
      // 인코딩된 ID로 삭제 요청
      await axios.delete(`/api/memberanking/${encodeURIComponent(id)}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });      
  
      setMembers(members.filter(member => member.id !== id));
      alert('회원이 삭제되었습니다.');
    } catch (error) {
      setErrorMessage('회원 삭제에 실패했습니다.');
      console.error('Error deleting member:', error);
    }
  };  

  // 포인트 수정 버튼 클릭 시
  const handleEditClick = (id, points) => {
    setEditingMemberId(id); // 수정할 회원 ID 저장
    setEditPoints(points); // 기존 포인트 값으로 초기화
  };

  // 포인트 수정 저장
  const handleSaveClick = async (id) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setErrorMessage('로그인이 필요합니다.');
        return;
      }

      // 서버에 포인트 업데이트 요청
      await axios.put(`/api/memberanking/${encodeURIComponent(id)}/points`, { points: editPoints }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });         

      // 업데이트 후 화면에서 수정 완료 상태로 변경
      setMembers(
        members.map(member =>
          member.id === id ? { ...member, points: editPoints } : member
        )
      );
      setEditingMemberId(null); // 수정 상태 초기화
      alert('포인트가 수정되었습니다.');
    } catch (error) {
      setErrorMessage('포인트 수정에 실패했습니다.');
      console.error('Error updating points:', error);
    }
  };

  // 정렬 함수
  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sortedMembers = [...members].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

    setMembers(sortedMembers);
  };

  return (
    <div>
      <h2>회원 목록</h2>
      {/* 에러 메시지 출력 */}
      {errorMessage && <p>{errorMessage}</p>}
      
      {/* 회원 목록 출력 */}
      <table className="members-table">
        <thead>
          <tr>
            <th onClick={() => requestSort('id')}>
              아이디 <span className={`arrow ${sortConfig.key === 'id' ? sortConfig.direction : ''}`}></span>
            </th>
            <th onClick={() => requestSort('username')}>
              별칭 <span className={`arrow ${sortConfig.key === 'username' ? sortConfig.direction : ''}`}></span>
            </th>
            <th onClick={() => requestSort('phone')}>
              전화번호 <span className={`arrow ${sortConfig.key === 'phone' ? sortConfig.direction : ''}`}></span>
            </th>
            <th onClick={() => requestSort('email')}>
              이메일 <span className={`arrow ${sortConfig.key === 'email' ? sortConfig.direction : ''}`}></span>
            </th>
            <th onClick={() => requestSort('points')}>
              포인트 <span className={`arrow ${sortConfig.key === 'points' ? sortConfig.direction : ''}`}></span>
            </th>
            <th>관리</th>
          </tr>
        </thead>
       <tbody>
  {members.length > 0 ? (
    members.map((member, index) => (
      <tr key={member.id || index}> {/* 고유한 값이 없으면 index 사용 */}
        <td>{member.id}</td> {/* 아이디 컬럼 */}
        <td>
          <Link to={`/admin/members/${member.id}`}>{member.username}</Link> {/* 별칭 */}
        </td>
        <td>{member.phone}</td>
        <td>{member.email}</td>
        <td>{member.points}</td>
        <td>
          {editingMemberId === member.id ? (
            <>
              <input
                type="number"
                value={editPoints}
                onChange={(e) => setEditPoints(e.target.value)}
              />
              <button onClick={() => handleSaveClick(member.id)}>저장</button>
              <button onClick={() => setEditingMemberId(null)}>취소</button>
            </>
          ) : (
            <>
              <button onClick={() => handleEditClick(member.id, member.points)}>수정</button>
              <button onClick={() => deleteMember(member.id)}>삭제</button>
            </>
          )}
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="7">회원 정보가 없습니다.</td>
    </tr>
  )}
</tbody>
      </table>
    </div>
  );
};

export default Amemberl;
