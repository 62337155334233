import React, { useState, useEffect, useCallback } from "react";
import { Route, Routes, Link, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';

// 컴포넌트 임포트
import PrivateRoute from './Admin/PrivateRoute';
import Anoticem from "./Admin/Anoticem";
import Anoticeedit from './Admin/Anoticeedit';
import Aregion from './Admin/Aregion';
import Amemberl from './Admin/Amemberl';
import Sign from "./Admin/Sign";
import Reset from "./Admin/Reset";
import Finduser from "./Admin/Finduser";
import Uprof from "./Admin/Uprof";
import Signud from './Admin/Signud';
import Main from "./Main";
import Noticelist from './Category/Noticelist';
import Noticedetail from './Category/Noticedetail';
import Freebdp from "./Category/Freebdp";
import Freebd from './Category/Freebd';
import Freetail from './Category/Freetail';
import Freebde from './Category/Freebde';
import Regionpage from "./Category/Regionpage";
import Promotiondetail from './Tournament/Promotiondetail';
import Promotionrequest from './Tournament/Promotionrequest';
import Promotionedit from './Tournament/Promotionedit';
import Atten from './Category/Atten';

//Tournament
import Tmultisetup from "./Tournament/Tmultisetup";
import Tmultigame from "./Tournament/Tmultigame";
import Tgametype from "./Tournament/Tgametype";
import Tournamentsetup from "./Tournament/Tournamentsetup";
import Tournamentgame from "./Tournament/Tournamentgame";
import Tsinglesetup from './Tournament/Tsinglesetup';
import Tsinglegame from "./Tournament/Tsinglegame";
import Tsinglerequest from "./Tournament/Tsinglerequest";
import Tsingleedit from "./Tournament/Tsingleedit";
import Tsingledetail from "./Tournament/Tsingledetail";

// 광고
import Ad1 from "./Ad/Ad1";
import Ad2 from "./Ad/Ad2";
import Ad3 from "./Ad/Ad3";
import GyPage from './Pages/gy';
import Adc from "./Adc";
import "../css/base.css";
import "../css/pag.css";
import "../css/table.css";
import '../css/signup.css';

// NotFound 컴포넌트
const NotFound = () => <h2>404 - 페이지를 찾을 수 없습니다.</h2>;

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  // 로그아웃 처리
  const handleLogout = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('isAdmin'); // 관리자가 로그아웃할 때 isAdmin도 지움
    setIsLoggedIn(false);
    setIsAdmin(false);
    navigate('/');
  }, [navigate]);

  // 관리자 여부 확인 함수
  const checkAdminStatus = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) return;

      const response = await axios.get("/api/check-admin", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200 && response.data.isAdmin) {
        setIsAdmin(true);
        localStorage.setItem('isAdmin', 'true'); // 관리자인지 로컬 스토리지에 저장
      } else {
        setIsAdmin(false);
        localStorage.setItem('isAdmin', 'false');
      }
    } catch (error) {
      console.error('Error checking admin status:', error.response ? error.response.data : error.message);
      setIsAdmin(false); // 에러가 발생하면 관리자가 아님
      localStorage.setItem('isAdmin', 'false');
    }
  };

  // useEffect에서 checkAdminStatus 함수 호출
  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedIsAdmin = localStorage.getItem('isAdmin') === 'true'; // 저장된 isAdmin 값 확인

    if (token) {
      setIsLoggedIn(true); // 토큰이 있으면 로그인 상태를 true로 설정
      setIsAdmin(storedIsAdmin); // 저장된 값으로 관리자 상태 설정
      checkAdminStatus(); // 관리자인지 확인
    }
  }, []);

  // 로그인 처리
  const handleLogin = async () => {
    try {
      const response = await axios.post('/api/login', { id, password });
      localStorage.setItem('token', response.data.token);

      setIsAdmin(response.data.isAdmin); // isAdmin 값을 서버에서 응답받고 설정
      localStorage.setItem('isAdmin', response.data.isAdmin ? 'true' : 'false');
      setIsLoggedIn(true);

      navigate('/');  // 로그인 후 메인 페이지로 이동

      if (response.data.isAdmin) {
        // 관리자인 경우 강제로 새로고침
        window.location.reload();
      }
    } catch (error) {
      console.error('Error logging in:', error);
      alert('로그인 실패');
    }
  };
  
  return (
    <div className="App main-container">
      <header className="App-header">
        <h2 className="h2-control">
          <Link to="/">CHIKKA</Link>
        </h2>

        {/* 공통 네비게이션 */}
        <nav className="category-nav">
          <Link to="/notice">공지사항</Link>
          <Link to="/tournament-type">당구장대회</Link>
          <Link to="/region">지역별당구장</Link>
          <Link to="/free-board">자유게시판</Link>
        </nav>

        {/* 로그인, 로그아웃, 프로필, 출석체크 */}
        <div className="auth-buttons-container">
          {isLoggedIn ? (
            <>
              <Link to="/attendance">
                <button className="attendance-button">출석체크</button>
              </Link>

              <Link to="/profile">
                <button className="profile-button">회원정보</button>
              </Link>
              {isAdmin && (
                <>
                  <Link to="/admin/notice-manage">
                    <button className="admin-button">공지사항 관리</button>
                  </Link>
                  <Link to="/admin/region-manage">
                    <button className="admin-button">당구장 관리</button>
                  </Link>
                  <Link to="/admin/members">
                    <button className="admin-button">회원목록 관리</button>
                  </Link>
                </>
              )}
              <button className="logout-button" onClick={handleLogout}>로그아웃</button>
            </>
          ) : (
            <div className="auth-buttons">
              <div className="login-container">
                <div className="input-fields-container">
                  <input
                    type="text"
                    placeholder="아이디"
                    value={id}
                    onChange={(e) => setId(e.target.value)}
                  />
                  <input
                    type="password"
                    placeholder="비밀번호"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <button className="login-button" onClick={handleLogin}>로그인</button>
              </div>
              <Link to="/find-username">
                <button>아이디 찾기</button>
              </Link>
              <Link to="/request-reset-password">
                <button>비밀번호 재설정</button>
              </Link>
              <Link to="/signup">
                <button>회원가입</button>
              </Link>
            </div>
          )}
        </div>
      </header>

      {/* 광고 배너 */}
      <div className="left-ad-container">
        <a href="https://link.coupang.com/a/bVn914" target="_blank" rel="noopener noreferrer">
          <img src="https://img5a.coupangcdn.com/image/affiliate/banner/869fa04e9668480a07609200eef3b3f5@2x.jpg" alt="광고" width="120" height="240"/>
        </a>
      </div>

      <div className="right-ad-container">
        <a href="https://link.coupang.com/a/bK0vEU" target="_blank" rel="noopener noreferrer">
          <img
            src="https://img2c.coupangcdn.com/image/affiliate/banner/f0ab7ce5882e7689627f77fc3a7514fe@2x.jpg"
            alt="광고"
            width="120"
            height="240"
          />
        </a>
      </div>

      {/* Adc */}
      <div className="nav-container-wrapper">
        {location.pathname === "/" && (
          <>
            <Adc path="/ad1" navImage={process.env.PUBLIC_URL + "/images/ad/P3.gif"} navText={["작당당구장", "천호동 288-28"]} />
            <Adc path="/ad2" navImage={process.env.PUBLIC_URL + "/images/ad/P4.gif"} navText={["다른 광고", "다른 주소"]} />
            <Adc path="/ad3" navImage={process.env.PUBLIC_URL + "/images/ad/P5.gif"} navText={["다른 광고2", "다른 주소2"]} />
          </>
        )}
      </div>

      {/* 메인 컨텐츠 */}
      <main>
        <Routes>
          {/* 일반 사용자 페이지 */}
          <Route path="/" element={<Main />} />
          <Route path="/notice" element={<Noticelist />} />
          <Route path="/notice/:id" element={<Noticedetail />} />
          <Route path="/promotion-board/:id" element={<Promotiondetail />} />
          <Route path="/promotion-board/new" element={<Promotionrequest />} />
          <Route path="/promotion-board/edit/:id" element={<Promotionedit />} />
          <Route path="/region" element={<Regionpage />} />
          <Route path="/free-board" element={<Freebdp />} />
          <Route path="/free-board/:id" element={<Freetail />} />
          <Route path="/free-board/edit/:id" element={<Freebde />} />
          <Route path="/free-board/new" element={<Freebd />} />

          {/*토너먼트*/}
          <Route path="/tournament" element={<Tournamentgame />} />
          <Route path="/tournament/single" element={<Tsinglesetup />} />
          <Route path="/tournament/single/start" element={<Tsinglegame />} />
          <Route path="/tournament/single/:id" element={<Tsingledetail />} />
          <Route path="/tournament/single/new" element={<Tsinglerequest />} />
          <Route path="/tournament/single/edit/:id" element={<Tsingleedit />} />
          <Route path="/tournament-type" element={<Tgametype />} />
          <Route path="/tournament/setup" element={<Tournamentsetup />} />
          <Route path="/tournament/multi" element={<Tmultisetup />} />
          <Route path="/tournament/multi/start" element={<Tmultigame />} />

          {/* 사용자 프로필 및 인증 페이지 */}
          <Route path="/profile" element={<Uprof />} />
          <Route path="/update-info" element={<Signud />} />
          <Route path="/signup" element={<Sign />} />
          <Route path="/request-reset-password" element={<Reset />} />
          <Route path="/find-username" element={<Finduser />} />

          <Route path="/attendance" element={<Atten />} />

          {/* 광고 페이지 */}
          <Route path="/ad1" element={<Ad1 adName="ad1" />} />
          <Route path="/ad2" element={<Ad2 adName="ad2" />} />
          <Route path="/ad3" element={<Ad3 adName="ad3" />} />

          {/* 관리자 페이지 */}
          <Route path="/admin/notice-manage" element={<PrivateRoute element={Anoticem} isAdmin={isAdmin} />} />
          <Route path="/admin/notice/edit/:id" element={<PrivateRoute element={Anoticeedit} isAdmin={isAdmin} />} />
          <Route path="/admin/notice/new" element={<PrivateRoute element={Anoticeedit} isAdmin={isAdmin} />} />
          <Route path="/admin/region-manage" element={<PrivateRoute element={Aregion} isAdmin={isAdmin} />} />
          <Route path="/admin/members" element={<PrivateRoute element={Amemberl} isAdmin={isAdmin} />} />

          {/* GY 페이지 */}
          <Route path="/gy" element={<GyPage />} />

          {/* NotFound Route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
    </div>
  );
};

export default App;
